<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <svg
      width="256"
      height="256"
      viewBox="0 0 256 256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 54H244M12 127.818H244M12 201.636H244"
        stroke="currentColor"
        stroke-width="21"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </svg>
</template>
